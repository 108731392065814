@import 'variables';
@import '~bootstrap/scss/bootstrap';

body,
html {
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow: hidden;

  * {
    font-family: 'Circe', serif;
    outline: none;
  }

  .pointer {
    cursor: pointer;
  }

  #root {
    height: 100%;
  }

  a:hover {
    text-decoration: none;
  }

  .form-control {
    border-color: transparent;
    border-width: 2px;
    box-shadow: unset !important;

    &:focus {
      &:not(.is-invalid) {
        border-color: $periwinkle;
      }
    }

    &.is-invalid {
      border-color: $danger;
      background-image: unset;
    }

    &::placeholder {
      color: $cloudy-blue-text;
    }
  }

  button {
    box-shadow: none !important;
  }
}
