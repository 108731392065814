$white: #ffffff;
$pale-grey: #f5f7fd;
$pale-grey-two: #eceff9;
$periwinkle: #887cfd;
$pale-peach: #ffd9a2;
$light-peach: #ffc2a8;
$cloudy-blue-text: #b5bfcb;
$slate-grey-title-text: #565c62;
$pale-sky-blue-platinum: #b3f1ff;
$powder-blue-silver: #bad7ff;

$base-theme: Bootstrap;
$skin-name: badges;
$swatch-name: Bootstrap;
$border-radius: 25px;
$primary: $periwinkle;
$accent: $periwinkle;
$secondary: $pale-grey;
$info: $periwinkle;
$success: #abd36c;
$warning: #ffc107;
$error: #fa7880;
$danger: #fa7880;
$body-bg: #ffffff;
$body-color: $periwinkle;
$component-bg: $pale-grey;
$input-bg: $pale-grey;
$component-color: #8a9499;
$card-cap-bg: #ffffff;
$card-cap-color: $slate-grey-title-text;
$series-a: #0275d8;
$series-b: #5bc0de;
$series-c: #5cb85c;
$series-d: #f0ad4e;
$series-e: #e67d4a;
$series-f: #d9534f;
$grid-gutter-width: 40px;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1280px,
);

$disabled: #b9bfc3;
$primary-dark: #6656fc;
$active-background: #5a4afc;

$btn-disabled-opacity: 0.5;

$header-height: 155px;
// $header-height: 120px;
$header-height-mobile: 56px;
$sidebar-width: 100px;

$bronze-color: $light-peach;
$silver-color: $powder-blue-silver;
$gold-color: $pale-peach;
$platinum-color: $pale-sky-blue-platinum;
